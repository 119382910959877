function standBig() {
    var carousel = $('.stand__carousel.big'),
        parent = carousel.parents('.stand');

    carousel.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        infinite: false,
        variableWidth: true,
        draggable: false
    });

    var slide = carousel.find('.slick-slide'),
        dots = carousel.find('.slick-dots li'),
        current = 2;

    function setHeight(idx, h) {
        var parentWidth = parent.innerWidth() - 72,
            wBig = (parentWidth * 65) / 100,
            wSmall = (parentWidth - wBig) / 4,
            part = 18,
            medium = h - (2 * part),
            small = h - (4 * part),
            meidumShift = part,
            smallShift = part * 2;

        var center = slide.eq(idx),
            prev = center.prev(),
            prevPrev = center.prev().prev(),
            next = center.next(),
            nextNext = center.next().next();

        dots.eq(1).remove();
        dots.eq(2).remove();
        dots.eq(12).remove();
        dots.eq(13).remove();

        prevPrev.css({width: wSmall + 'px'});
        prevPrev.find('.stand__img').css({top: smallShift + 'px', height: small + 'px'});
        prev.css({width: wSmall + 'px'});
        prev.find('.stand__img').css({top: meidumShift + 'px', height: medium + 'px'});
        center.css({width: wBig + 'px'});
        center.find('.stand__img').css({top: 0, height: h + 'px'});
        next.find('.stand__img').css({top: meidumShift + 'px', height: medium + 'px'});
        next.css({width: wSmall + 'px'});
        nextNext.find('.stand__img').css({top: smallShift + 'px', height: small + 'px'});
        nextNext.css({width: wSmall + 'px'});
    }

    setHeight(2, 414);

    carousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var next = nextSlide + 2;
        current = next;
        setHeight(next, 414)
    });

    $(window).resize(function () {
        setTimeout(function () {
            setHeight(current, 414)
        }, 200)
    })
}

function standSmall() {
    var carousel = $('.stand__carousel.small');

    carousel.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true
    });
}

$(document).ready(function () {
    standBig();
    standSmall()
});